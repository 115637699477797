// Integrations

import './integrations-callback-urls'
import './integrations-edit'
import './integrations-transfer'

import {changeValue} from '@github-ui/form-utils'
import {on} from 'delegated-events'
import {onKey} from '@github-ui/onfocus'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import {getCheckedSelector} from './personal-access-tokens'

interface ActionMenuElement extends HTMLElement {
  disableItem(item: Element | null): void
  enableItem(item: Element | null): void
  checkItem(item: Element | null): void
  getItemById(id: string): HTMLLIElement
}

function enableFormSubmission() {
  const updatePermissionsButton = document.getElementById('js-update-integration-permissions')

  if (updatePermissionsButton) {
    updatePermissionsButton.removeAttribute('disabled')
  }
}

// A list of the currently selected "resource" permissions for the Integration
// based on the permissions drop downs.

// E.g. ["issues", "pull_requests", "metadata"]
function selectedResources(): string[] {
  const resources = document.querySelectorAll<HTMLInputElement | HTMLButtonElement>(
    `.js-integration-permissions-selector [id^=integration_permission_]:not([data-permission="none"])${getCheckedSelector()}`,
  )

  return Array.from(resources, resource => resource.getAttribute('data-resource')!)
}

// A NodeList of all the '.js-integration-hook-event' input nodes that are:
// 1. Currently 'checked'
// 2. Belong to the given "resource". E.g. "issues", "pull_requests" etc.
function checkedHookEventsForResource(resource: string | null) {
  if (!resource) return []
  return Array.from(
    document.querySelectorAll<HTMLInputElement>(`.js-integration-hook-event[data-resource~="${resource}"]:checked`),
  )
}

// A list of all the '.js-integration-hook-event' input nodes that are:
// 1. Currently 'checked'
// 2. Belong to resources permissions that are selected. E.g. "Read", "Read &
//    Write" or "Admin".
function checkedHookEventNodes() {
  return selectedResources().flatMap(resource => checkedHookEventsForResource(resource))
}

// Can the given Hook event be hidden based on the chosen Integration
// resource permissions?
// E.g. When Issues is "Read" but Pull Requests is "None" the "Milestones"
// event should still be visible because it applies to *both* resource types.
function hookEventCheckboxCanBeHidden(node: HTMLInputElement): boolean {
  return !checkedHookEventNodes().includes(node)
}

on('change', '.js-integration-permissions-selector [id^=integration_permission_]', ({currentTarget}) => {
  const permission = currentTarget.getAttribute('data-permission') || 'none'
  const resource = currentTarget.getAttribute('data-resource') || ''

  configureHookEvents(permission, resource, currentTarget)
})

on('itemActivated', '#action-selection-list-menu', (event: CustomEvent) => {
  const selectedItem = event.detail.item as HTMLLIElement
  const itemButton = selectedItem.querySelector<HTMLButtonElement>('button')!
  if (!itemButton) return

  const permission = itemButton.getAttribute('data-permission') || 'none'
  const resource = itemButton.getAttribute('data-resource') || ''

  configureHookEvents(permission, resource, itemButton)
})

function configureHookEvents(
  permission: string,
  resource: string,
  currentTarget: (EventTarget & Element) | HTMLButtonElement,
) {
  // One event can apply to multiple resources (E.g. milestones apply to both
  // Issues and Pull Requests). To model this in HTML data-resource can
  // contain one or more resource names separated by spaces. E.g.
  // data-resource="issues pull_requests", which we select using the ~= CSS
  // attribute condition.
  const hookEvents = document.querySelectorAll<HTMLInputElement>(
    `.js-integration-hook-event[data-resource~="${resource}"]`,
  )
  const permissionContainers = document.querySelectorAll(`.js-dropdown-container[data-resource~="${resource}"]`)

  enableFormSubmission()

  if (permission !== 'none') {
    const hookPermissionErrors = document.querySelectorAll(
      `.js-integration-hook-event-permission-error[data-resource~='${resource}']`,
    )
    for (const error of hookPermissionErrors) {
      /* eslint-disable-next-line github/no-d-none */
      error.classList.add('d-none')
    }

    for (const el of hookEvents) {
      el.readOnly = false
    }

    currentTarget.closest<HTMLElement>('.js-list-group-item')!.classList.remove('disabled')
    for (const el of permissionContainers) {
      /* eslint-disable-next-line github/no-d-none */
      el.classList.remove('d-none')
    }
  } else {
    // The integrator has selected "none" as the permission for this resource.
    // Hide all Hook event checkboxes related to this resource:
    for (const el of hookEvents) {
      if (hookEventCheckboxCanBeHidden(el)) {
        // Disable the event hook checkbox
        el.readOnly = true
        el.checked = false

        // Hide the hook checkbox's container
        /* eslint-disable-next-line github/no-d-none */
        el.closest<HTMLElement>('.js-dropdown-container')!.classList.add('anim-fade-in', 'd-none')
      }
    }

    // Disable the current resource permission checkbox so we don't send a
    // value to the server when this form is submitted.
    currentTarget.closest<HTMLElement>('.js-list-group-item')!.classList.add('disabled')

    if (resource === 'single_file') {
      clearSubmittedFields(resource, '.js-single-files-path-list')
    }
  }
}

function clearSubmittedFields(resource: string, selectedList: string) {
  // Hide the resource container
  /* eslint-disable-next-line github/no-d-none */
  document
    .querySelector<HTMLElement>(`.js-dropdown-container[data-resource~='${resource}']`)!
    .classList.add('anim-fade-in', 'd-none')

  document.querySelector<HTMLElement>(selectedList)!.textContent = ''
}

// Toggle the 'required' state of the Hook URL input Field to let users know
// that they need to provide a value when the hook is active.
function toggleHookUrlRequired(active: boolean) {
  const hookUrlField = document.querySelector<HTMLElement>('.js-hook-url-field')
  if (!hookUrlField) return

  hookUrlField.classList.toggle('required')
  if (active) {
    hookUrlField.setAttribute('required', 'required')
  } else {
    hookUrlField.removeAttribute('required')
  }

  const hookUrlFieldContainer = hookUrlField.closest('dl.form-group')
  if (!hookUrlFieldContainer) return

  hookUrlFieldContainer.classList.toggle('required')
}

on('change', '.js-hook-active-checkbox', function (event) {
  const currentTarget = event.currentTarget as HTMLInputElement
  for (const el of document.querySelectorAll<HTMLElement>('.js-integration-hook-enabled')) {
    el.hidden = !currentTarget.checked
  }

  toggleHookUrlRequired(currentTarget.checked)
})

export function toggleMetadataSelectionMenu(mandatory = false) {
  if (isFeatureEnabled('fgpat_form_ui_updates')) {
    const metadataReadButton = document.querySelector<HTMLButtonElement>(
      '.js-integration-permissions-selector [id=integration_permission_metadata_read]',
    )

    if (metadataReadButton) {
      const row = metadataReadButton.closest<HTMLElement>('.js-list-group-item')!
      const selected = metadataReadButton.ariaChecked === 'true'

      handleMetadataRowLabels(row, selected, mandatory)
      toggleActionMenu(row, mandatory)
    }
  } else {
    const metadataReadInput = document.querySelector<HTMLInputElement>(
      '.js-integration-permissions-selector [id=integration_permission_metadata_read]',
    )!

    if (mandatory) {
      changeValue(metadataReadInput, true)
    }

    const row = metadataReadInput.closest<HTMLElement>('.js-list-group-item')!
    row.querySelector<HTMLElement>('.js-mandatory-label')!.hidden = !mandatory

    for (const input of row.querySelectorAll<HTMLInputElement>('input')) {
      if (input !== metadataReadInput) input.disabled = mandatory
    }
  }
}

function handleMetadataRowLabels(row: HTMLElement, selected: boolean, mandatory = false) {
  row.querySelector<HTMLElement>('.js-mandatory-label')!.hidden = !mandatory

  const selectedLabel = row.querySelector<HTMLElement>('.js-selected-label')
  if (selectedLabel) {
    selectedLabel.hidden = mandatory || !selected
  }
}

function toggleActionMenu(row: HTMLElement, mandatory = false) {
  const actionMenu: ActionMenuElement = row.querySelector('#action-selection-list-menu')!
  const actionMenuShowButton = row.querySelector<HTMLButtonElement>('.js-action-selection-list-menu-button')!
  const actionMenuTooltip: HTMLElement = actionMenu.querySelector('[id^=tooltip]')!

  if (mandatory) {
    actionMenuShowButton.ariaDisabled = 'true'
    actionMenuShowButton.classList.add('Button--inactive')

    if (actionMenuTooltip) {
      actionMenuTooltip.style.display = 'block'
      actionMenuTooltip.hidden = false
    }
  } else {
    actionMenuShowButton.ariaDisabled = 'false'
    actionMenuShowButton.classList.remove('Button--inactive')

    if (actionMenuTooltip) {
      actionMenuTooltip.style.display = 'none'
      actionMenuTooltip.hidden = false
    }
  }

  const actionListItems = row.querySelectorAll<HTMLLIElement>('[data-targets="action-list.items"]')
  for (const listItem of actionListItems) {
    if (mandatory) {
      actionMenu.disableItem(listItem)

      const listItemButton = listItem.querySelector<HTMLButtonElement>('button')!
      const listItemPermission = listItemButton?.getAttribute('data-permission')
      if (listItemPermission === 'read') {
        listItemButton.setAttribute('data-programmatic', 'true')
        actionMenu.checkItem(listItem)
      }
    } else {
      actionMenu.enableItem(listItem)
    }
  }
}

function noRepoPermissionSelected(): boolean {
  let noneSelected = true

  const allSelectedRepoPermissions = document.querySelectorAll(
    `.js-integration-permissions-selector [id^=integration_permission_][data-resource-parent=repository]${getCheckedSelector()}`,
  )

  for (const repoPermission of allSelectedRepoPermissions) {
    const permission = repoPermission.getAttribute('data-permission') || 'none'
    const resource = repoPermission.getAttribute('data-resource') || ''
    if (resource !== 'metadata' && permission !== 'none') {
      noneSelected = false
      break
    }
  }
  return noneSelected
}

on('itemActivated', '[data-repository-permissions] #action-selection-list-menu', (event: CustomEvent) => {
  const selectedItem = event.detail.item as HTMLLIElement
  const itemButton = selectedItem.querySelector<HTMLButtonElement>('button')!

  const resourceParent = itemButton.getAttribute('data-resource-parent') || ''
  if (resourceParent !== 'repository') return

  const permission = itemButton.getAttribute('data-permission') || 'none'
  const resource = itemButton.getAttribute('data-resource') || ''

  handleMetadataMenu(resource, permission)
})

// Handle the mandatory repository metadata permission:
//
// When an integrator selects _any_ repository-related permission they are
// _always_ granted the metadata permission.
//
// To make this clear in the UI we select 'Read-only' in the repository
// metadata permissions select menu and disable the menu until _no_ repository
// permissions are selected.
on(
  'change',
  '.js-integration-permissions-selector [id^=integration_permission_][data-resource-parent^=repository]',
  function ({currentTarget}) {
    const permission = currentTarget.getAttribute('data-permission') || 'none'
    const resource = currentTarget.getAttribute('data-resource') || ''

    handleMetadataMenu(resource, permission)
  },
)

function handleMetadataMenu(resource: string, permission: string) {
  if (resource === 'metadata') return

  if (permission === 'none') {
    if (noRepoPermissionSelected()) {
      // If the repository "No Access" permission is selected, and it was the only permission, re-enable the
      // repository-metadata menu so that the user can deselect it if they want.
      toggleMetadataSelectionMenu(false)
    }
  } else {
    toggleMetadataSelectionMenu(true)
  }
}

on('change', '.js-integration-permissions-selector [name^=integration]', enableFormSubmission)

on('click', '.js-integration-permissions-selector .js-integration-hook-event', function (event) {
  const target = event.currentTarget as HTMLInputElement

  if (target.readOnly) {
    const sendEvents = target.closest<HTMLElement>('.js-send-events')!
    const errorMessage = sendEvents.querySelector<HTMLElement>('.js-integration-hook-event-permission-error')!
    /* eslint-disable-next-line github/no-d-none */
    errorMessage.classList.remove('d-none')
    event.preventDefault()
  }
})

function addPath() {
  const input = document.querySelector<HTMLInputElement>('.js-single-files input')!
  const template = document.querySelector<HTMLTemplateElement>('.js-single-files template')!
  const list = document.querySelector<HTMLElement>('.js-single-files-path-list')!

  const path = input.value
  if (!path) return

  const item = template.content.cloneNode(true)
  if (!(item instanceof DocumentFragment)) return
  item.querySelector<HTMLInputElement>('input')!.value = path
  item.querySelector<HTMLElement>('.js-single-files-path')!.textContent = path
  input.value = ''

  list.appendChild(item)

  list.closest<HTMLElement>('.Box')!.hidden = false

  enableFormSubmission()
}

onKey('keydown', '.js-single-files input', function (event: KeyboardEvent) {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  if (event.key !== 'Enter') return
  event.preventDefault()
  addPath()
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

on('click', '.js-single-files .js-path-add', addPath)

on('click', '.js-single-files .js-close', function (event) {
  if (!(event.target instanceof Element)) return
  const item = event.target.closest<HTMLElement>('li')!
  const list = item.parentElement
  if (!list) return
  list.removeChild(item)

  if (list.querySelectorAll('li').length === 0) {
    list.closest<HTMLElement>('.Box')!.hidden = true
  }

  enableFormSubmission()
})
