// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// When user selects an item from the autocomplete list, update the selected
on('click', '.js-transfer-target-autocomplete-item', function (event) {
  const target = event.currentTarget
  if (!(target instanceof Element)) return

  const container = target.closest<HTMLElement>('.js-add-access-search')
  if (!container) return

  const selection = container.querySelector<HTMLElement>('.js-transfer-search-selection')
  const selected = container.querySelector<HTMLElement>('.js-transfer-search-selected')
  const selectionBadge = container.querySelector<HTMLElement>('.js-selected-transfer-badge')
  const itemSelectionBadge = target.querySelector<HTMLElement>('.js-transfer-selection-badge')

  if (!selection || !selected || !selectionBadge || !itemSelectionBadge) return

  // Copy badge contents
  selectionBadge.innerHTML = itemSelectionBadge.innerHTML

  // Toggle visibility
  selection.hidden = true
  selected.hidden = false

  const transferTargetValue = itemSelectionBadge.querySelector('[data-transfer-to]')?.getAttribute('data-transfer-to')
  const transferToInput = document.querySelector<HTMLInputElement>('#js-transfer-search-input')
  if (transferToInput && transferTargetValue) {
    transferToInput.value = transferTargetValue
  }

  // Handle uninstall warning banner visibility
  const warningBanner = document.querySelector<HTMLElement>('#uninstall-warning-banner')

  if (warningBanner) {
    const hasInstallations = warningBanner.getAttribute('data-has-installations') === 'true'
    const isPrivateApp = warningBanner.getAttribute('data-is-private-app') === 'true'
    const orgOwner = warningBanner.getAttribute('data-owner-type') === 'Organization'
    const enterpriseTarget = transferTargetValue?.startsWith('Business/')

    if (orgOwner && enterpriseTarget) {
      warningBanner.hidden = true
    } else {
      warningBanner.hidden = !(hasInstallations && isPrivateApp)
    }
  }

  // Trigger form validation to update submit button disabled state
  const form = container.closest<HTMLFormElement>('.js-transfer-form')
  if (!form) return
  form.dispatchEvent(new Event('change'))
})

// Update the cancel handler to trigger form validation
on('click', '.js-cancel-transfer', function (event) {
  if (!(event.target instanceof Element)) return
  const container = event.target.closest<HTMLElement>('.js-add-access-search')
  if (!container) return

  const selection = container.querySelector<HTMLElement>('.js-transfer-search-selection')
  const selected = container.querySelector<HTMLElement>('.js-transfer-search-selected')
  const input = container.querySelector<HTMLInputElement>('#js-transfer-search-input')

  if (!selection || !selected || !input) return

  // Clear input and toggle visibility
  input.value = ''
  selection.hidden = false
  selected.hidden = true

  // Hide the warning banner when selection is cleared
  const warningBanner = document.querySelector<HTMLElement>('#uninstall-warning-banner')
  if (warningBanner) {
    warningBanner.hidden = true
  }

  // Trigger form validation to update submit button disabled state
  const form = container.closest<HTMLFormElement>('.js-transfer-form')
  if (!form) return
  form.dispatchEvent(new Event('change'))
})

// Add form validation observer
observe('.js-transfer-form', form => {
  const submitButton = form.querySelector<HTMLButtonElement>('button[type="submit"]')
  if (!form || !submitButton) return

  form.addEventListener('change', () => {
    if (!(form as HTMLFormElement).checkValidity()) {
      submitButton.setAttribute('aria-disabled', 'true')
      submitButton.disabled = true
    } else {
      submitButton.removeAttribute('aria-disabled')
      submitButton.disabled = false
    }
  })
})
