// GitHub Apps: Installation repository select menu behavior
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import type {ItemActivatedEvent} from '@primer/view-components/app/components/primer/shared_events'

function selectedRepositories() {
  return document.querySelectorAll<HTMLInputElement>('.js-selected-repository-field')
}

function isMaxRepositoriesSelected() {
  const form = document.querySelector('.js-integrations-install-form')
  if (form instanceof HTMLFormElement) {
    const max = +(form.getAttribute('data-max-repos') || 0)
    return max > 0 && selectedRepositories().length >= max
  }
}

// Fetch new menu content, excluding selected repositories
function toggleRepoVisibilityFromResults() {
  const menu = document.querySelector<HTMLElement>('#repository-menu-list')!
  const ids = Array.from(selectedRepositories()).map(input => input.value)
  let anyVisible = false
  for (const item of menu.querySelectorAll<HTMLButtonElement>('[role=menuitem]')) {
    const hidden = ids.includes(item.value)
    if (!anyVisible && !hidden) anyVisible = true
    item.hidden = hidden
  }
  const noRepo = menu.querySelector('.js-no-repository-found')
  if (noRepo instanceof HTMLElement) noRepo.hidden = anyVisible
}

// Disable the submit and cancel buttons if repository selection is required
function updateFormValidity() {
  const selectRepos = document.querySelector<HTMLInputElement>('.js-select-repositories-radio')
  if (!selectRepos) return

  const formIsValid = !selectRepos.checked || selectedRepositories().length > 0
  toggleEnable('js-integrations-install-form-submit', formIsValid)
  toggleEnable('js-integrations-install-form-cancel', true)
}

// Enable/disable button matching certain class
function toggleEnable(className: string, enable: boolean) {
  const button = document.querySelector(`.${className}`)
  if (button && button instanceof HTMLButtonElement) {
    if (button.disabled === enable) {
      button.disabled = !enable
    }
  }
}

// Remove repository selection inputs if repository selection is not required
function toggleRepoSelectionSection() {
  const selectRepos = document.querySelector<HTMLInputElement>('.js-select-repositories-radio')
  const repoSection = document.querySelector<HTMLElement>('.js-integrations-install-repo-selection')!
  if (!selectRepos) return

  repoSection.classList.toggle('has-removed-contents', !selectRepos.checked)
}

function updateRepositoriesSelectedCopy() {
  const count = selectedRepositories().length
  const repoCountEl = document.querySelector<HTMLElement>('.js-integration-total-repos')!
  const badge = document.querySelector<HTMLElement>('.js-integration-total-repos-badge')
  repoCountEl.hidden = count === 0
  // eslint-disable-next-line i18n-text/no-en
  repoCountEl.textContent = `Selected ${count} ${count === 1 ? 'repository' : 'repositories'}.`
  if (badge) {
    badge.textContent = count.toString()
    badge.hidden = count === 0
  }
}

observe('.js-apps-install-select-repositories-container remote-input', el => {
  el.addEventListener('remote-input-success', toggleRepoVisibilityFromResults)
  el.addEventListener('remote-input-error', toggleRepoVisibilityFromResults)
})

on(
  'itemActivated',
  '#repository-menu-list',
  function (event: CustomEvent<ItemActivatedEvent>) {
    const li = event.detail.item
    const checked = event.detail.checked
    const value = li.querySelector('button')!.getAttribute('data-value')
    const row = document.querySelector<HTMLElement>(`.js-repository-picker-result input[value="${value}"]`)
      ?.parentElement

    if (checked && !row) {
      // Copy template content and insert selected repository into the "picked" list
      const pickedCountainer = document.querySelector<HTMLElement>('.js-integrations-install-repo-picked')!
      const result = li.querySelector<HTMLTemplateElement>('template')!.content.cloneNode(true)
      pickedCountainer.prepend(result)

      // Remove any errors from the form
      document.querySelector<HTMLElement>('.js-min-repository-error')!.hidden = true
      document.querySelector<HTMLElement>('.js-max-repository-warning')!.hidden = !isMaxRepositoriesSelected()

      // Update selected repo counts and enable the install/save button if
      // necessary
      updateRepositoriesSelectedCopy()
      updateFormValidity()
      toggleRepoVisibilityFromResults()
    } else if (row) {
      row.remove()

      // Show an error message if no repos are selected
      document.querySelector<HTMLElement>('.js-min-repository-error')!.hidden = selectedRepositories().length > 0

      updateRepositoriesSelectedCopy()
      updateFormValidity()
      toggleRepoVisibilityFromResults()
    }
  },
  {capture: true},
)

on('change', '.js-integrations-install-form', updateFormValidity)
on('change', '.js-user-programmatic-access-form', updateFormValidity)

on('change', '.js-installation-repositories-radio', toggleRepoSelectionSection)

on('click', '.js-integrations-install-form-cancel', function () {
  window.location.reload()
})

// TODO remove the following event listeners when the following code is converted to Primer's SelectPanel
// app/views/reminders/form/_tracked_repositories.html.erb
// app/views/reminders/form/_repository_suggestion_selection.html.erb
on(
  'details-menu-selected',
  '.js-apps-install-select-repositories-menu',
  function (event) {
    const button = event.detail.relatedTarget as Element

    // Copy template content and insert selected repository into the "picked" list
    const pickedCountainer = document.querySelector<HTMLElement>('.js-integrations-install-repo-picked')!
    const result = button.querySelector<HTMLTemplateElement>('template')!.content.cloneNode(true)
    pickedCountainer.prepend(result)

    // Remove any errors from the form
    document.querySelector<HTMLElement>('.js-min-repository-error')!.hidden = true
    document.querySelector<HTMLElement>('.js-max-repository-warning')!.hidden = !isMaxRepositoriesSelected()

    // Update selected repo counts and enable the install/save button if
    // necessary
    updateRepositoriesSelectedCopy()
    updateFormValidity()
    toggleRepoVisibilityFromResults()
  },
  {capture: true},
)

on('click', '.js-repository-picker-remove', function (event) {
  // Remove the element from the "Picked" list
  const row = event.currentTarget.closest<HTMLElement>('.js-repository-picker-result')!
  row.remove()

  // Show an error message if no repos are selected
  document.querySelector<HTMLElement>('.js-min-repository-error')!.hidden = selectedRepositories().length > 0

  updateRepositoriesSelectedCopy()
  updateFormValidity()
  toggleRepoVisibilityFromResults()
})
